<template>
  <div>
    <headBack>
      <template v-slot:title>
        <div class='title'>
          客服服务
        </div>
      </template>
    </headBack>
    <div class='header_cont'></div>
    <div class="kef">
      <iframe
          src="https://im.7x24cc.com/?accountId=N000000007036&chatId=qyjk-6f8fc5f0-9b49-11e6-bf1e-03bffe68584e"
          sandbox="allow-same-origin allow-scripts"
          width="100%"
          height="100%"
          frameborder="0"
      ></iframe>
    </div>

  </div>

</template>

<script>
export default {
  name: "kefu",
  setup () {

  }
}
</script>

<style scoped lang="scss">
   .kef{
     height: calc(100vh - 88px);
     box-sizing: border-box;
     iframe{
       //border:1px solid #00C291;
     }
   }
</style>
